import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTaxi = () => {
  const toast = useToast();

  const deleteTaxi = async (taxi) => {
    try {
      await store.dispatch('taxi/remove', taxi.id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the taxi',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const fetchTaxi = async (taxiId) => {
    return await store.dispatch('taxi/get', taxiId);
  };

  const getTaxiList = async () => {
    try {
      return await store.dispatch('taxi/getList');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of taxis',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    deleteTaxi,
    fetchTaxi,
    getTaxiList,
  };
};

export default useTaxi;
