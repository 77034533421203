var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-taxi-form","visible":_vm.isActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.taxiData.id ? 'Update' : 'Add New')+" Taxi")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.resetForm();
          hide();}}})],1),_c('validation-observer',{ref:"newTaxiForm"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"First Name","label-for":"first-name"}},[_c('b-form-input',{attrs:{"id":"first-name","state":errors.length > 0 ? false : null,"name":"first-name","placeholder":"First name"},model:{value:(_vm.taxiData.firstName),callback:function ($$v) {_vm.$set(_vm.taxiData, "firstName", $$v)},expression:"taxiData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last-name"}},[_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false : null,"name":"last-name","placeholder":"Last name"},model:{value:(_vm.taxiData.lastName),callback:function ($$v) {_vm.$set(_vm.taxiData, "lastName", $$v)},expression:"taxiData.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","name":"email","placeholder":"Email"},model:{value:(_vm.taxiData.email),callback:function ($$v) {_vm.$set(_vm.taxiData, "email", $$v)},expression:"taxiData.email"}})],1),_c('b-form-group',{attrs:{"label":"Contact Num","label-for":"contact-num"}},[_c('b-form-input',{attrs:{"id":"contact-num","name":"contact-num"},model:{value:(_vm.taxiData.contactTel),callback:function ($$v) {_vm.$set(_vm.taxiData, "contactTel", $$v)},expression:"taxiData.contactTel"}})],1),_c('validation-provider',{attrs:{"name":"Licence Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Licence Number","label-for":"licence-number"}},[_c('b-form-input',{attrs:{"id":"licence-number","state":errors.length > 0 ? false : null,"name":"licence-number","placeholder":"Licence Number"},model:{value:(_vm.taxiData.licenceNum),callback:function ($$v) {_vm.$set(_vm.taxiData, "licenceNum", $$v)},expression:"taxiData.licenceNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Is Member","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is Member","label-for":"is-meber"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-member"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.taxiData.isMember),callback:function ($$v) {_vm.$set(_vm.taxiData, "isMember", $$v)},expression:"taxiData.isMember"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Is in Radio Shift","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is in Radio Shift","label-for":"is-radio-shift"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"agent"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.taxiData.isInRadioShift),callback:function ($$v) {_vm.$set(_vm.taxiData, "isInRadioShift", $$v)},expression:"taxiData.isInRadioShift"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.taxiData.id ? 'Update' : 'Add')+" ")],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.resetForm();
              hide();}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }