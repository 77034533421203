import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import useUtils from "@/composables/useUtils";

const useTaxiList = () => {
  const toast = useToast();
  const {formatCurrency} = useUtils();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'licenceNum', sortable: true },
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'contactTel', label: 'Contact Num' },
    {
      key: 'balance',
      formatter: (val) => formatCurrency(val),
    },
    { key: 'member' },
    { key: 'radioShift' },
    { key: 'actions' },
  ];

  const pageNumber = ref(1);
  const orderDesc = ref(false);
  const pageSize = ref(10);
  const refTaxisTable = ref(null);
  const searchQuery = ref(null);
  const orderBy = ref('id');
  const totalTaxis = ref(0);

  const dataMeta = computed(() => {
    const localItemsCount = refTaxisTable.value
      ? refTaxisTable.value.localItems.length
      : 0;

    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalTaxis.value,
    };
  });

  watch([pageNumber, pageSize], () => {
    refTaxisTable.value.refresh();
  });

  const getTaxisList = (_, callback) => {
    const params = {
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      searchQuery: searchQuery.value,
    };

    store
      .dispatch('taxi/getList', params)
      .then(({ taxis, total }) => {
        callback(taxis);
        totalTaxis.value = total;
      })
      .catch(() => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Error fetching taxi list',
            icon: 'XCircleIcon',
          },
        });
      });
  };

  return {
    // Const
    perPageOptions,
    tableColumns,

    // Reactive
    pageNumber,
    orderDesc,
    pageSize,
    refTaxisTable,
    searchQuery,
    orderBy,
    totalTaxis,

    // Computed
    dataMeta,

    // Method
    getTaxisList,
  };
};

export default useTaxiList;
