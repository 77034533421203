const useUtils = () => {
  const formatDateDdMmYyyy = (date) => {
    const dateObj = new Date(date);
    const yyyy = dateObj.getFullYear();
    const mm = dateObj.getMonth() + 1;
    const dd = dateObj.getDate();

    return `${dd}/${mm}/${yyyy}`;
  };

  const formatDateDdMmYyyyHi = (date) => {
    const yyyy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    const h = String(date.getHours()).padStart(2, '0');
    const i = String(date.getMinutes()).padStart(2, '0');

    return `${dd}/${mm}/${yyyy} ${h}:${i}`;
  };

  const convertUtcToLocalDate = (date) => {
    const utcDate = new Date(date);
    return new Date(
      Date.UTC(
        utcDate.getFullYear(),
        utcDate.getMonth(),
        utcDate.getDate(),
        utcDate.getHours(),
        utcDate.getMinutes()
      )
    );
  };

  const convertDateToUTC = (date) => {
    if (typeof date === 'string' || date instanceof String) {
      const [dateValues, timeValues] = date.split(' ');
      const [day, month, year] = dateValues.split('/');

      if (timeValues !== undefined) {
        const [hours, minutes] = timeValues.split(':');
        return new Date(year, month - 1, day, hours, minutes, '00').toJSON();
      }

      return new Date(Date.UTC(year, month - 1, day));
    } else {
      return new Date(date).toJSON();
    }
  };

  const formatDecimalNumber = (number, numOfDecimals = 2) =>
    parseFloat(number.toFixed(numOfDecimals));

  const generateInitials = (firstName, lastName) =>
    (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();

  const formatCurrency = (fee) =>
    fee < 0
      ? `- £${new Intl.NumberFormat('en-GB').format(Math.abs(fee))}`
      : `£${new Intl.NumberFormat('en-GB').format(Math.abs(fee))}`;

  return {
    convertDateToUTC,
    convertUtcToLocalDate,
    formatDateDdMmYyyy,
    formatDateDdMmYyyyHi,
    formatDecimalNumber,
    generateInitials,
    formatCurrency,
  };
};

export default useUtils;
