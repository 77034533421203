<template>
  <div>
    <taxi-form
      v-if="isTaxiFormActive"
      :is-active.sync="isTaxiFormActive"
      :taxi="taxi"
      @form-submitted="formSubmitted"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>

            <v-select
              v-model="pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Press ENTER to search"
                @keyup.enter="refTaxisTable.refresh();"
              />

              <b-button
                variant="primary"
                @click=" openForm "
              >
                <span class="text-nowrap">Add Taxi</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="taxis-table"
        ref="refTaxisTable"
        class="position-relative"
        :items=" getTaxisList "
        responsive
        :fields=" tableColumns "
        primary-key="id"
        :sort-by.sync=" orderBy "
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync=" orderDesc "
        style="min-height:110px"
      >
        <template #cell(licenceNum)=" data ">
          <b-link
            :to=" { name: 'taxi-details', params: { id: data.item.id } } "
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.licenceNum }}
          </b-link>
        </template>

        <template #cell(name)=" data ">
          <p class="font-weight-bold d-block text-nowrap">
            {{ data.item.firstName + ' ' + data.item.lastName }}
          </p>
        </template>

        <template #cell(email)=" data ">
          {{ data.item.email }}
        </template>

        <template #cell(contactTel)=" data ">
          {{ data.item.contactTel }}
        </template>

        <template #cell(member)=" data ">
          <b-badge
            pill
            :variant=" (data.item.isMember) ? 'light-success' : 'light-secondary' "
            class="text-capitalize"
          >
            {{ (data.item.isMember) ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <template #cell(radioShift)=" data ">
          <b-badge
            pill
            :variant=" (data.item.isInRadioShift) ? 'light-success' : 'light-secondary' "
            class="text-capitalize"
          >
            {{ (data.item.isInRadioShift) ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <template #cell(actions)=" data ">
          <b-dropdown
            variant="link"
            no-caret
            :right=" $store.state.appConfig.isRTL "
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to=" { name: 'taxi-details', params: { id: data.item.id } } ">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Manage</span>
            </b-dropdown-item>

            <b-dropdown-item @click=" editTaxi(data.item) ">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model=" pageNumber "
              :total-rows=" totalTaxis "
              :per-page=" pageSize "
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import Swal from 'sweetalert2';
import useTaxiList from '@/modules/taxi/composables/useTaxiList';
import TaxiForm from '@/modules/taxi/components/TaxiForm.vue';
import useTaxi from '@/modules/taxi/composables/useTaxi';

export default {
  setup() {
    const { deleteTaxi } = useTaxi();
    const {
      dataMeta,
      getTaxisList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refTaxisTable,
      searchQuery,
      tableColumns,
      totalTaxis,
    } = useTaxiList();

    const emptyTaxi = {
      firstName: null,
      id: null,
      isInRadioShift: null,
      isMember: null,
      lastName: null,
      email: null,
      contactTel: null,
      licenceNum: null,
    };
    const isTaxiFormActive = ref(false);
    const taxi = ref({ ...emptyTaxi });

    const editTaxi = (taxiSelected) => {
      taxi.value = { ...taxiSelected };
      isTaxiFormActive.value = true;
    };

    const formSubmitted = () => {
      isTaxiFormActive.value = false;
      taxi.value = { ...emptyTaxi };
      refTaxisTable.value.refresh();
    };

    const openForm = () => {
      taxi.value = { ...emptyTaxi };
      isTaxiFormActive.value = true;
    };

    const removeTaxi = async (taxi) => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deleteTaxi(taxi);

      refTaxisTable.value.refresh();
    };

    return {
      //Reactive
      isTaxiFormActive,
      taxi,

      // Methods
      editTaxi,
      formSubmitted,
      openForm,
      removeTaxi,

      // UseTaxiList
      dataMeta,
      getTaxisList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refTaxisTable,
      searchQuery,
      tableColumns,
      totalTaxis,
    };
  },
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    TaxiForm,
    vSelect,
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

#taxis-table {
  min-height: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>