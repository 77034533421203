<template>
  <b-sidebar
    id="add-new-taxi-form"
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ taxiData.id ? 'Update' : 'Add New' }} Taxi</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <validation-observer ref="newTaxiForm">
        <b-form
          class="p-2"
          @submit.prevent="validateForm()"
        >
          <validation-provider
            #default="{ errors }"
            name="First Name"
            rules="required"
          >
            <b-form-group
              label="First Name"
              label-for="first-name"
            >
              <b-form-input
                id="first-name"
                v-model="taxiData.firstName"
                :state="errors.length > 0 ? false : null"
                name="first-name"
                placeholder="First name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Last Name"
            rules="required"
          >
            <b-form-group
              label="Last Name"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="taxiData.lastName"
                :state="errors.length > 0 ? false : null"
                name="last-name"
                placeholder="Last name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="taxiData.email"
              name="email"
              placeholder="Email"
            />
          </b-form-group>

          <b-form-group
            label="Contact Num"
            label-for="contact-num"
          >
            <b-form-input
              id="contact-num"
              v-model="taxiData.contactTel"
              name="contact-num"
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Licence Number"
            rules="required"
          >
            <b-form-group
              label="Licence Number"
              label-for="licence-number"
            >
              <b-form-input
                id="licence-number"
                v-model="taxiData.licenceNum"
                :state="errors.length > 0 ? false : null"
                name="licence-number"
                placeholder="Licence Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Is Member"
            rules="required"
          >
            <b-form-group
              label="Is Member"
              label-for="is-meber"
            >
              <v-select
                v-model="taxiData.isMember"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="is-member"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Is in Radio Shift"
            rules="required"
          >
            <b-form-group
              label="Is in Radio Shift"
              label-for="is-radio-shift"
            >
              <v-select
                v-model="taxiData.isInRadioShift"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="agent"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ taxiData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import store from '@/store';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    taxi: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const toast = useToast();

    const taxiData = ref(props.taxi);
    const newTaxiForm = ref(null);
    const isLoading = ref(false);

    const resetForm = () => {
      newTaxiForm.value.reset();
    };

    const validateForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await newTaxiForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      try {
        taxiData.value.id
          ? await store.dispatch('taxi/update', taxiData.value)
          : await store.dispatch('taxi/create', taxiData.value);

        ctx.emit('form-submitted');
      } catch (error) {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'An error occurred.',
            icon: 'XCircleIcon',
          },
        });
      }

      isLoading.value = false;
    };

    return {
      // Reacitve
      newTaxiForm,
      taxiData,
      isLoading,

      // Method
      resetForm,
      validateForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-taxi-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
